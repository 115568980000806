import * as React from 'react';
import * as LabelPrimitive from '@radix-ui/react-label';
import { Slot } from '@radix-ui/react-slot';
import {
  Controller,
  ControllerProps,
  FieldPath,
  FieldValues,
  FormProvider,
  useFormContext,
} from 'react-hook-form';

import { cn } from '~/utils/tw';
import { PhoneNumberInput } from '~/components/phone-number-input';
import { Label } from 'app/components/ui/label';
import { useTranslation } from 'react-i18next';
import { RiInformationLine } from '@remixicon/react';
import { Input, InputProps } from './input';
import { Textarea, TextareaProps } from './textarea';
import { VariantProps, cva } from 'class-variance-authority';
import { TimeField, TimeFieldProps } from '../date-time-picker/time-field';
import { AddressInput, AddressInputProps } from '../address-input.client';
import { ClientOnly } from '../client-only';
import { LoadingSpinner } from './spinner';
import { Calendar } from './calendar';
import { ComponentProps } from 'react';

const Form = FormProvider;

type FormFieldContextValue<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  name: TName;
};

const FormFieldContext = React.createContext<FormFieldContextValue>(
  {} as FormFieldContextValue,
);

const FormField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  ...props
}: ControllerProps<TFieldValues, TName>) => {
  return (
    <FormFieldContext.Provider value={{ name: props.name }}>
      <Controller {...props} />
    </FormFieldContext.Provider>
  );
};

const messageVariants = cva('font-medium text-sm', {
  variants: {
    variant: {
      default: 'text-destructive',
      warning: 'text-warning',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

const useFormField = () => {
  const fieldContext = React.useContext(FormFieldContext);
  const itemContext = React.useContext(FormItemContext);
  const { getFieldState, formState } = useFormContext();

  const fieldState = getFieldState(fieldContext.name, formState);

  if (!fieldContext) {
    throw new Error('useFormField should be used within <FormField>');
  }

  const { id } = itemContext;

  return {
    id,
    name: fieldContext.name,
    formItemId: `${id}-form-item`,
    formDescriptionId: `${id}-form-item-description`,
    formMessageId: `${id}-form-item-message`,
    ...fieldState,
  };
};

type FormItemContextValue = {
  id: string;
};

const FormItemContext = React.createContext<FormItemContextValue>(
  {} as FormItemContextValue,
);

const FormItem = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & { className?: string }
>(({ className, ...props }, ref) => {
  const id = React.useId();

  return (
    <FormItemContext.Provider value={{ id }}>
      <div ref={ref} className={cn('w-full', className)} {...props} />
    </FormItemContext.Provider>
  );
});
FormItem.displayName = 'FormItem';

const FormLabel = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & {
    className?: string;
  }
>(({ className, ...props }, ref) => {
  const { formItemId } = useFormField();

  return (
    <Label ref={ref} className={className} htmlFor={formItemId} {...props} />
  );
});
FormLabel.displayName = 'FormLabel';

const FormControl = React.forwardRef<
  React.ElementRef<typeof Slot>,
  React.ComponentPropsWithoutRef<typeof Slot>
>(({ ...props }, ref) => {
  const { error, formItemId, formDescriptionId, formMessageId } =
    useFormField();

  return (
    <Slot
      ref={ref}
      id={formItemId}
      aria-describedby={
        !error
          ? `${formDescriptionId}`
          : `${formDescriptionId} ${formMessageId}`
      }
      aria-invalid={!!error}
      {...props}
    />
  );
});
FormControl.displayName = 'FormControl';

const FormDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement> & { className?: string }
>(({ className, ...props }, ref) => {
  const { formDescriptionId } = useFormField();

  return (
    <p
      ref={ref}
      id={formDescriptionId}
      className={cn('text-sm text-muted-foreground', className)}
      {...props}
    />
  );
});
FormDescription.displayName = 'FormDescription';

const FormMessage = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement> &
    VariantProps<typeof messageVariants>
>(({ className, variant, children, ...props }, ref) => {
  const { t } = useTranslation();
  const { error, formMessageId } = useFormField();

  const body = error?.message ? String(error?.message) : children;

  if (!body) {
    return null;
  }

  return (
    <div
      ref={ref}
      id={formMessageId}
      className={cn(messageVariants({ variant }), className)}
      {...props}
    >
      <div className="mt-2 flex">
        <RiInformationLine size={14} className="mr-1" />
        <span className="leading-tight">{t(`${body}`)}</span>
      </div>
    </div>
  );
});
FormMessage.displayName = 'FormMessage';

const FormInput = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, ...props }, ref) => {
    const { error } = useFormField();
    return (
      <Input
        ref={ref}
        className={cn(error && '!border-destructive', className)}
        onKeyDown={(e) => {
          e.key === 'Enter' && e.preventDefault();
        }}
        {...props}
      />
    );
  },
);
FormInput.displayName = 'FormInput';

const FormPhoneInput = React.forwardRef<
  HTMLInputElement,
  React.InputHTMLAttributes<HTMLInputElement> & { className?: string }
>(({ className, ...props }, ref) => {
  const { error } = useFormField();
  return (
    <PhoneNumberInput
      ref={ref}
      className={cn(error && '!border-destructive', className)}
      {...props}
    />
  );
});
FormPhoneInput.displayName = 'FormPhoneInput';

const FormAddressInput = React.forwardRef<HTMLInputElement, AddressInputProps>(
  ({ className, ...props }, ref) => {
    const { error } = useFormField();
    return (
      <ClientOnly fallback={<LoadingSpinner />}>
        {() => (
          <AddressInput
            ref={ref}
            className={cn(error && '!border-destructive', className)}
            {...props}
          />
        )}
      </ClientOnly>
    );
  },
);
FormAddressInput.displayName = 'FormAddressInput';

const FormTextArea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, ...props }, ref) => {
    const { error } = useFormField();
    return (
      <Textarea
        ref={ref}
        className={cn(error && '!border-destructive', className)}
        {...props}
      />
    );
  },
);

FormTextArea.displayName = 'FormTextArea';

const FormTimePicker = ({ className, ...props }: TimeFieldProps) => {
  const { error } = useFormField();
  return (
    <TimeField
      className={cn(error && '!border-destructive', className)}
      {...props}
    />
  );
};
FormTimePicker.displayName = 'FormTimePicker';

const FormCalendar = ({ ...props }: ComponentProps<typeof Calendar>) => {
  return <Calendar {...props} />;
};

FormCalendar.displayName = 'FormCalendar';

export {
  useFormField,
  Form,
  FormItem,
  FormLabel,
  FormControl,
  FormDescription,
  FormMessage,
  FormField,
  FormInput,
  FormPhoneInput,
  FormTextArea,
  FormTimePicker,
  FormAddressInput,
  FormCalendar,
};
