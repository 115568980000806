import * as React from 'react';
import { cn } from 'app/utils/tw';

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  maxLength?: number;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, value, maxLength, ...props }, ref) => {
    return (
      <div className="relative">
        <textarea
          className={cn(
            'border-styles relative flex min-h-20 w-full resize-y rounded-sm border border-input bg-background px-3 py-2 text-sm placeholder:text-muted-foreground focus:border-homy-gray-light focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-homy-gray-lighter  disabled:text-homy-gray-light',
            className,
          )}
          ref={ref}
          value={value}
          maxLength={maxLength}
          {...props}
        />
        {maxLength && (
          <div className="pointer-events-none absolute bottom-0 right-0 pb-2 pr-5 text-sm text-homy-gray">
            <span
              className={cn({
                'text-destructive': String(value).length === maxLength,
              })}
            >
              {String(value).length}
            </span>{' '}
            / <span className={'text-homy-gray-lighter'}>{maxLength}</span>
          </div>
        )}
      </div>
    );
  },
);
Textarea.displayName = 'Textarea';

export { Textarea };
