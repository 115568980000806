import {
  CountryIso2,
  defaultCountries,
  FlagImage,
  parseCountry,
  usePhoneInput,
} from 'react-international-phone';
import { cn } from '~/utils/tw';
import React, { ChangeEvent, InputHTMLAttributes } from 'react';
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
} from '~/components/ui/select';

const PhoneNumberInput = React.forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement> & { defaultCountry?: CountryIso2 }
>(
  (
    {
      placeholder,
      className,
      value,
      onChange,
      defaultCountry = 'ca',
      ...props
    },
    ref,
  ) => {
    const {
      inputValue,
      handlePhoneValueChange,
      inputRef,
      country,
      setCountry,
    } = usePhoneInput({
      value: value as string,
      defaultCountry,
      onChange: (data) => {
        const event = {
          target: {
            value: data.phone,
          },
        } as ChangeEvent<HTMLInputElement>;
        onChange?.(event);
      },
      disableDialCodePrefill: true,
    });

    return (
      <div className="group relative flex w-full items-center" ref={ref}>
        <Select value={`${country.iso2}`} onValueChange={setCountry}>
          <SelectTrigger className="absolute left-0 h-auto w-auto border-none bg-transparent px-4 pr-0 hover:shadow-none">
            <FlagImage iso2={country.iso2} className="mr-1 h-5" />
          </SelectTrigger>
          <SelectContent>
            {defaultCountries.map((countryData) => {
              const c = parseCountry(countryData);
              return (
                <SelectItem key={c.iso2} value={`${c.iso2}`}>
                  <div className="flex flex-row items-center gap-2">
                    <FlagImage iso2={c.iso2} className="h-4" />
                    <p color="text-homy-gray">{c.name}</p>
                    <p color="text-homy-gray">+{c.dialCode}</p>
                  </div>
                </SelectItem>
              );
            })}
          </SelectContent>
        </Select>
        <input
          ref={inputRef}
          value={inputValue || '+1'}
          onChange={handlePhoneValueChange}
          placeholder={placeholder}
          className={cn(
            'flex h-10 w-full rounded-sm border border-input bg-background px-4 py-2 pl-16 text-sm text-homy-gray-darker ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-homy-gray-light hover:shadow-sm focus:border-homy-gray-darker/50 focus-visible:outline-none active:border-homy-gray-light disabled:cursor-not-allowed',
            className,
          )}
          {...props}
        />
      </div>
    );
  },
);
PhoneNumberInput.displayName = 'PhoneNumberInput';

export { PhoneNumberInput };
