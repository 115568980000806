import { useDebounce } from '@uidotdev/usehooks';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useToast } from './ui/use-toast';
import { useTranslation } from 'react-i18next';
import { Input, InputProps } from './ui/input';
import { Command, CommandGroup, CommandItem, CommandList } from './ui/command';
import { geocode } from '~/utils/geocoding.client';
import { LoadingSpinner } from './ui/spinner';
import { cn } from '~/utils/tw';
import { SEARCH_DEBOUNCE_VALUE } from '~/constants';

export type Address = {
  label: string;
  coordinates: number[] | undefined;
};

export type AddressState = {
  value?: Address;
  options?: Address[];
};

export type AddressChangeEvent = ChangeEvent<HTMLInputElement> & {
  target: { value: AddressState };
};

export type AddressInputProps = {
  value?: AddressState;
  onChange: (event: AddressChangeEvent) => void;
} & Omit<InputProps, 'value' | 'onChange'>;

const createAddressInputEvent = (state: AddressState) =>
  ({
    target: {
      value: state,
    },
  }) as unknown as AddressChangeEvent;

const AddressInput = React.forwardRef<HTMLInputElement, AddressInputProps>(
  ({ value: state = {}, onChange, ...props }, ref) => {
    const { t } = useTranslation();
    const { toast } = useToast();

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const { value, options } = state;

    const debouncedSearchValue = useDebounce(
      value?.label,
      SEARCH_DEBOUNCE_VALUE,
    );

    useEffect(() => {
      //Check if debouncedSearchValue is already in options
      if (options?.find((option) => option?.label === debouncedSearchValue)) {
        return setOpen(false);
      }

      (async (searchValue?: string) => {
        if (!searchValue) return;

        // Reset state
        setLoading(true);
        setOpen(false);

        try {
          // Fetch addresses
          const addresses = await geocode(searchValue);
          if (addresses.length) {
            setOpen(true);
          }
          onChange?.(
            createAddressInputEvent({
              value: { label: searchValue, coordinates: undefined },
              options: addresses.map(({ label, x, y }) => ({
                label,
                // Coordinates are in reverse order
                coordinates: [y, x],
              })),
            }),
          );
        } catch (error) {
          toast({
            variant: 'destructive',
            title: t('toasts.error'),
            description: t('toasts.errorMessage'),
          });
        } finally {
          setLoading(false);
        }
      })(debouncedSearchValue);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchValue, toast, t]);

    const onResultSelect = (result: Address) => {
      onChange?.(createAddressInputEvent({ value: result, options }));
      setOpen(false);
    };

    return (
      <div>
        <Input
          ref={ref}
          {...props}
          value={value?.label}
          autoComplete="off"
          onChange={(e) => {
            onChange?.(
              createAddressInputEvent({
                // Reset options and update label value
                value: { label: e.target.value, coordinates: undefined },
                options: [],
              }),
            );
            setOpen(false);
          }}
          {...(loading && {
            endIcon: {
              icon: <LoadingSpinner />,
            },
          })}
        />
        <Command
          shouldFilter={false}
          className={cn(
            'rounded-t-none border border-t-0 border-homy-gray-lighter',
            {
              'border-none': !open,
            },
          )}
        >
          {open ? (
            <CommandList>
              <CommandGroup>
                {options?.map(({ label, coordinates }) => (
                  <CommandItem
                    key={label}
                    value={label}
                    className="aria-selected:bg-homy-green"
                    onSelect={() => onResultSelect({ label, coordinates })}
                  >
                    {label}
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          ) : null}
        </Command>
      </div>
    );
  },
);

AddressInput.displayName = 'AddressInput';

export { AddressInput };
